export const checkPhoneInString = (value: string): boolean =>
    /(?:\+?(99|357|7|380|375|38|8)[\s-]?(\d{1,4})?[\s-]*)?(?=[\d⁰¹²³⁴⁵⁶⁷⁸⁹₀₁₂₃₄₅₆₇₈₉]*)([\d⁰¹²³⁴⁵⁶⁷⁸⁹₀₁₂₃₄₅₆₇₈₉][\s-⁰¹²³⁴⁵⁶⁷⁸⁹₀₁₂₃₄₅₆₇₈₉]*){6,}/gm.test(value.replace(/[\s()_-]/g, ''));

export const containsHtmlTags = (value: string) => /<\/?[a-z][\s\S]*>/gi.test(value);

export const checkEmailInString = (value: string): boolean =>
    /([a-zA-Z0-9._-]+@[a-zA-Z0-9_-]+\.(com|com\.cy|org|net|edu|gov|info|biz|io|co|me|mil|xyz|pro|name|online|store|tech|tv|cc|us|ca|ru|fr|de|jp|br|in|cn|au|pl|es|it|nl|se|fi|dk|no|ch|hk|at|cz|ro|be|hu|gr|ie|pt|il|sg|za|tw|ph|my|ae|th|ng|pk|vn|cl|pe|ar|co\.uk|org\.uk|ac\.uk|gov\.uk|edu\.au|gov\.au|ac\.au|gmail\.com|yahoo\.com|hotmail\.com))+/gi.test(value) ||
    /([a-zA-Z0-9._-]+ )?(gmail\.com|yahoo\.com|hotmail\.com|mail\.ru|yandex\.ru|(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})/gi.test(value);

export const checkSocialLinkInString = (value: string): boolean => /(http:\/\/|https:\/\/|www.|vk|instagram|facebook|ru-ru.facebook|tiktok|tlgrm|t|viber:|wa|twitter)\.(com|ru|me)\/[a-zA-Z0-9._-]+/gi?.test(
    value
);

export const checkAtInString = (value: string): boolean => /(@)/gi?.test(
    value
)

export const checkStringForForbiddenWords = (value: string): boolean => {
    return containsHtmlTags(value) || checkPhoneInString(value) || checkEmailInString(value) || checkSocialLinkInString(value) || checkAtInString(value)
}

const utmWords = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'].join('|')

export const checkUtm = (): boolean => {
    return new RegExp('\\b' + utmWords + '\\b').test(window.location.search)
}