import Form from "../Form/Form";
import Input from "../Form/Input";
import RadioInput from "../Form/RadioInput";
import TextArea from "../Form/TextArea";
import MediaInput from "../Form/MediaInput";
import Checkbox from "../Form/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    checkReCaptcha,
    getDirectories,
    getPath,
    getUrl,
} from "../../store/directories/selectors";
import { useCreateFeedbackMutation } from "../../store/feedback/feedback.api";
import { getProfile, getTkn, getUrlAfterAuthorization } from "../../store/user/selectors";
import { TranslationObject } from "../../models/translationModal";
import WarnModal from "../Modals/WarnModal";
import classNames from "classnames";
import { useActions } from "../../hooks/actions";
import { ProfilePayload } from "../../models/userModels";
import { Block } from "../../models/directoryModel";
import _ from "lodash";
import { getFeedBackFields } from "../../store/feedback/selectors";
import { feedBackState } from "../../store/feedback/feebback.slice";
import { useDispatch } from "react-redux";

const FeedBackForm: React.FC<{
    entities?: TranslationObject;
    currentLanguage: string;
    type: string;
    profile?: ProfilePayload;
    feedbackTypeList?: Block;
    feedbackTypeListTitle?: string;
}> = ({
    entities,
    currentLanguage,
    type,
    profile,
    feedbackTypeList,
    feedbackTypeListTitle,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const path = getPath();
    const nextUrl = getUrlAfterAuthorization();
    const token = getTkn();
    const { updateUserField, setFeedBackField, clearFeedBackField, removeFeedBackFile, clearFeedBackFiles } =
        useActions();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [update, { error, isLoading, isSuccess }] =
        useCreateFeedbackMutation();
    const { data: directories } = getDirectories();
    const site_key = directories?.recaptcha?.site_key;
    const url = (str: string) => getUrl(str);
    const [title, setTitle] = useState<string>("");
    const name = getFeedBackFields()?.name;
    const needAuth = getFeedBackFields()?.needAuth;
    const email = getFeedBackFields()?.email;
    const text = getFeedBackFields()?.text;
    const files = getFeedBackFields()?.files;
    const [description, setDescription] = useState<string>("");
    const questionType = getFeedBackFields()?.questionType;
    const personalDataAgreement = getFeedBackFields()?.personalDataAgreement;
    const isAdvertisers = type === "advertisers";
    const isSupport = type === "write_us";
    const isBonus = type === "bonuses_and_partners";
    const urlParams = new URLSearchParams(window.location.search);
    const isReferral = urlParams.get('action') === 'referral';
    const updateField = (
        name: keyof feedBackState,
        value: feedBackState[keyof feedBackState]
    ) => {
        dispatch(
            setFeedBackField({
                name,
                value,
            })
        );
    };

    const removeFieldFiles = (
        name: keyof feedBackState,
        value: any
    ) => {
        dispatch(
            removeFeedBackFile({
                name,
                value,
            })
        );
    };

    const clearFieldFiles = () => {
        dispatch(clearFeedBackFiles());
    };

    useEffect(() => {
        updateField('name', (profile?.name && profile?.family_name) ? `${profile.name} ${profile.family_name}` : '')
        updateField('email', profile?.email || '')
    }, [profile]);

    useEffect(() => {
        if (isAdvertisers) {
            updateField("questionType", "ad");
        }
    }, [isAdvertisers]);

    useEffect(() => {
        if (needAuth && profile?.id && token) {
            const button = document.querySelector(".feedback-form__button");
            if (button instanceof HTMLElement) {
                button.click();
            }
        }
    }, [needAuth, profile, token]);

    useEffect(() => {
        if (nextUrl) {
            updateUserField({
                name: "urlAfterAuthorization",
                value: null,
            });
        }
    }, []);

    const onSubmit = async (e: any) => {
        e.preventDefault();
        setTitle("");
        setDescription("");
        const data = new FormData(e.target);
        const savedFile = files;
        const skipAuth = isBonus || isAdvertisers || isSupport;
        const isAuth = profile?.id && token;

        if (skipAuth || isAuth) {
            if (files) {
                for(let i = 0; i < savedFile.length; i++) {
                    const blob = await fetch(savedFile[i].file).then((r) => r.blob());
                    data.append(`files[${i}]`, blob, savedFile[i].name);
                }
            }

            if (site_key) {
                return checkReCaptcha(site_key, type, (token) =>
                    sendFeedback(token, data)
                );
            }
            return;
        }
        updateUserField({
            name: "urlAfterAuthorization",
            value: `/${currentLanguage}/${path[1]}/${
                isAdvertisers ? "advertisers" : "support"
            }`,
        });

        updateField("needAuth", true);

        navigate(`../${currentLanguage}/${path[1]}/auth`, {
            replace: true,
        });
    };

    const sendFeedback = (str: string, data: FormData) => {
        data.append("g-recaptcha-response", str);
        update({ body: data });
    };

    const onSuccess = () => {
        setShowModal(true);

        const button: HTMLButtonElement | null = document.querySelector(
            ".feedback-form__file-delete"
        );

        if (button) {
            button.click();
        }

        setFeedBackField("needAuth", false);

        if (files) {
            clearFieldFiles();
        }

        setTitle(entities?.title_complaint_has_been_sent?.value || "");
        setDescription(entities?.body_complaint_has_been_sent?.value || "");
        clearFeedBackField();
    };

    return (
        <>
            <WarnModal
                title={title}
                onClose={() => setShowModal(false)}
                visible={showModal}
                description={description}
            />
            <Form
                entities={entities}
                error={error}
                onSubmit={onSubmit}
                onSuccess={onSuccess}
                isSuccess={isSuccess}
                id={type}
            >
                {(invalidFields, error) => (
                    <div className="feedback-form__form-wrapper">
                        <div
                            className={classNames("", {
                                "feedback-form__user-field": !isSupport,
                                "form__row form__row--big-margin": isSupport,
                            })}
                        >
                            <Input
                                classes={[
                                    !isSupport
                                        ? "feedback-form__input"
                                        : "form__field",
                                ]}
                                required
                                value={name}
                                onChange={(value: string) =>
                                    updateField("name", value)
                                }
                                invalidFields={invalidFields}
                                error={error}
                                type="text"
                                placeholder={
                                    entities?.bonuses_and_partners_user_name
                                        ?.value || ""
                                }
                                id="user_name"
                                name="user_name"
                            />
                            <Input
                                classes={[
                                    !isSupport
                                        ? "feedback-form__input"
                                        : "form__field",
                                ]}
                                required
                                invalidFields={invalidFields}
                                error={error}
                                value={email}
                                onChange={(value: string) =>
                                    updateField("email", value)
                                }
                                type="email"
                                placeholder={
                                    entities?.bonuses_and_partners_email
                                        ?.value || ""
                                }
                                id="email"
                                name="email"
                            />
                            <input name="source" value={type} type="hidden" />
                        </div>
                        {isSupport && (
                            <div className="form__row">
                                <p className="feedback-form__title">
                                    {feedbackTypeListTitle ||
                                        entities?.type_of_question?.value}
                                </p>
                                {feedbackTypeList?.value?.map((el: string, index: number) => (
                                    <RadioInput
                                        required={questionType?.length < 1}
                                        key={_.uniqueId("question_type")}
                                        name="question_type"
                                        error={error}
                                        invalidFields={invalidFields}
                                        onClick={() =>
                                            updateField("questionType", el)
                                        }
                                        label={el || ""}
                                        value={el}
                                        checked={(index === 3 && isReferral) || questionType === el}
                                    />
                                )) || (
                                    <>
                                        <RadioInput
                                            required={questionType?.length < 1}
                                            name="question_type"
                                            label={
                                                entities?.wishes_and_suggestions
                                                    ?.value ?? ""
                                            }
                                            onClick={() =>
                                                updateField(
                                                    "questionType",
                                                    "wishes_and_suggestions"
                                                )
                                            }
                                            value="wishes_and_suggestions"
                                            checked={
                                                questionType ===
                                                "wishes_and_suggestions"
                                            }
                                        />
                                        <RadioInput
                                            required={questionType?.length < 1}
                                            name="question_type"
                                            label={
                                                entities
                                                    ?.complaint_against_specialist
                                                    ?.value ?? ""
                                            }
                                            checked={
                                                questionType ===
                                                "against_specialist"
                                            }
                                            onClick={() =>
                                                updateField(
                                                    "questionType",
                                                    "against_specialist"
                                                )
                                            }
                                            value="against_specialist"
                                        />
                                        <RadioInput
                                            required={questionType?.length < 1}
                                            name="question_type"
                                            checked={
                                                questionType ===
                                                "against_customer"
                                            }
                                            label={
                                                entities
                                                    ?.complaint_against_customer
                                                    ?.value ?? ""
                                            }
                                            onClick={() =>
                                                updateField(
                                                    "questionType",
                                                    "against_customer"
                                                )
                                            }
                                            value="against_customer"
                                        />
                                    </>
                                )}
                            </div>
                        )}
                        {isBonus && (
                            <Input
                                required
                                classes={["feedback-form__input"]}
                                invalidFields={invalidFields}
                                error={error}
                                type="text"
                                value={questionType}
                                onChange={(value: string) =>
                                    updateField("questionType", value)
                                }
                                placeholder={
                                    entities
                                        ?.bonuses_and_partners_theme_placeholder
                                        ?.value || ""
                                }
                                id="question_type"
                                name="question_type"
                            />
                        )}

                        <div className="feedback-form__type-field">
                            {isAdvertisers && (
                                <>
                                    {" "}
                                    <p className="feedback-form__title">
                                        {entities
                                            ?.bonuses_and_partners_text_placeholder
                                            ?.value || ""}
                                    </p>
                                    <RadioInput
                                        required={questionType?.length < 1}
                                        name="question_type"
                                        onClick={() =>
                                            updateField("questionType", "ad")
                                        }
                                        checked={questionType === "ad"}
                                        value="ad"
                                        label={
                                            entities?.advertising_link_value
                                                ?.value ?? ""
                                        }
                                    />
                                    {/*
                                   СКРЫТ 18.01.2023 по поручению Леры
                                   <RadioInput
                                        required={questionType?.length < 1}
                                        onClick={() => updateField("questionType", "partnership")}
                                        checked={questionType === "partnership"}
                                        name="question_type"
                                        value="partnership"
                                        label={
                                            entities
                                                ?.bonuses_and_partners_collaboration
                                                ?.value ?? ""
                                        }
                                    />*/}
                                </>
                            )}
                            <TextArea
                                classes={["feedback-form__textarea"]}
                                invalidFields={invalidFields}
                                error={error}
                                type="text"
                                defaultValue={text}
                                onChange={(value) => updateField("text", value)}
                                placeholder={
                                    isAdvertisers
                                        ? entities
                                              ?.advertisement_text_placeholder
                                              ?.value
                                        : entities
                                              ?.bonuses_and_partners_text_placeholder
                                              ?.value || ""
                                }
                                required
                                id="text"
                                name="text"
                            />
                            <MediaInput
                                name="files[]"
                                resetFiles={isSuccess}
                                invalidFields={invalidFields}
                                error={error}
                                text={
                                    entities?.bonuses_and_partners_attach_file
                                        ?.value || ""
                                }
                                maxFiles={3}
                                updateField={updateField}
                                removeFieldFiles={removeFieldFiles}
                            />
                            <Checkbox
                                invalidFields={invalidFields}
                                error={error}
                                required
                                name="personal_data_agreement"
                                value={personalDataAgreement}
                                onChange={() =>
                                    updateField(
                                        "personalDataAgreement",
                                        !personalDataAgreement
                                    )
                                }
                            >
                                {
                                    entities?.bonuses_and_partners_agree_with
                                        ?.value
                                }{" "}
                                <Link
                                    to={url("privacy-policy")}
                                    className="link link--hover-underline "
                                >
                                    {
                                        entities
                                            ?.bonuses_and_partners_personal_data_policy
                                            ?.value
                                    }
                                </Link>
                            </Checkbox>
                            <button
                                className="btn btn btn--blue feedback-form__button"
                                type="submit"
                                disabled={isLoading}
                            >
                                {
                                    entities?.bonuses_and_partners_send_button
                                        ?.value
                                }
                            </button>
                        </div>
                    </div>
                )}
            </Form>
        </>
    );
};

export default FeedBackForm;
