import { LocationData, locationProps } from "../models/orderModel";
import { DateTime } from "luxon";
import {
    AddressByCoordinatesResult,
    AddressComponent,
} from "../models/googleModel";
import { isSafariBrowser } from "../store/directories/selectors";
import { transliterate } from 'transliteration';
import { Service } from "../models/userModels";
import { SERVICE_SUB_TYPE_IDS, SERVICE_SUB_TYPE_SLUG, SERVICE_TYPE_ID, SERVICE_TYPE_SLUG } from "../store/order/constant";
import { createOrderState } from "../store/order/createOrder.slice";

export const chooseDeclension = (num = 0, words, isRus) => {
    if (!words || words === "") {
        return "";
    }

    const array = words?.split("|");
    num %= 100;

    if (num >= 11 && num <= 19) {
        if (isRus) {
            return array[2];
        }
        return array[1];
    }

    num %= 10;

    if (num === 1) {
        return array[0];
    }

    if (num > 0 && num <= 4) {
        return array[1];
    }

    return isRus ? array[2] : array[1];
};

function scrollToTopPolyfill() {
    const px = document.documentElement.scrollTop || document.body.scrollTop;
    if (px > 0) {
        window.requestAnimationFrame(scrollToTopPolyfill);
        window.scrollTo(0, px - px / 12);
    }
}

export const scrollTop = () => {
    if (isSafariBrowser()) {
        scrollToTopPolyfill();
    } else {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }
};

export const getItemStore = (str: string) => {
    const item = localStorage.getItem(str);
    if (item) {
        return typeof item === "string" ? JSON.parse(item) : item;
    }
    return null;
};

export const setItemStore = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const deleteItemStore = (key: string) => {
    localStorage.removeItem(key);
};

export const getLocationObj = (location: LocationData) => {
    let obj: locationProps = {
        lat: location?.latitude,
        lng: location?.longitude,
        address: location?.address,
    };

    if (location?.radius) {
        obj = {
            ...obj,
            address: location.address,
            radius: location?.radius,
        };
    }
    return obj;
};

export const formatQueryString = (params: Record<string, any>): string => {
    return _formatRecord(params);
};

type BooleanValues = { true: number; false: number };
const _booleanValues = { true: 1, false: 0 };

function _formatKeyValuePair(value: any, key: string): string {
    const type = Array.isArray(value) ? "array" : typeof value;
    switch (type) {
        case `number`:
        case `string`:
            return `${key}=${value}`;
        case `boolean`:
            return `${key}=${_booleanValues[value as keyof BooleanValues]}`;
        case `array`:
            return _formatArray(value, key);
        case `object`:
            return _formatRecord(value, key);
    }
    return "";
}

function _formatRecord(
    values: Record<string, any>,
    parentKey?: string
): string {
    const buffer: string[] = [];
    for (const key in values) {
        const value = values[key];
        if (value == undefined) {
            continue;
        }
        const resultKey = parentKey ? `${parentKey}[${key}]` : `${key}`;
        buffer.push(_formatKeyValuePair(value, resultKey));
    }
    return buffer.join("&");
}

function _formatArray(values: any[], parentKey: string): string {
    const buffer: string[] = [];
    for (const key in values) {
        const value = values[key];
        if (value == undefined) {
            continue;
        }
        buffer.push(_formatKeyValuePair(value, `${parentKey}[${key}]`));
    }
    return buffer.join("&");
}

export const getPages = (i: number) => {
    const pages = [];
    let j = 1;
    for (j; j <= i; j++) {
        pages.push(j);
    }
    return pages;
};

export const convertDateFormatISO = (value: Date | string) =>
    DateTime.fromJSDate(value).toISODate();

export const convertDateToJSDate = (value: string) =>
    DateTime.fromISO(value).toJSDate();

export const convertDateToDDMMYYYY = (value: string) =>
    DateTime.fromISO(value).toFormat("d.MM.yyyy");

export const convertDateToDDMMMMYYYY = (value: string, lg: string) =>
    DateTime.fromISO(value).setLocale(lg).toFormat("d MMMM yyyy");

export const toCollectAddress = (data: AddressByCoordinatesResult[]) => {
    const items = data[0]?.address_components;
    const postal_code = items?.find((elem: AddressComponent) =>
        elem.types.includes("postal_code")
    )?.short_name;
    const plus_code = items?.find((elem: AddressComponent) =>
        elem.types.includes("plus_code")
    )?.short_name;

    return data[0]?.formatted_address
        ?.replace(`, ${postal_code}`, "")
        ?.replace(`${plus_code}`, "");
};

export const arrayMove = (arr: any[], oldIndex: number, newIndex: number) => {
    if (!arr?.length) return arr;
    if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr;
};

export const getUrlTransliteName = (name: string) => {
    return transliterate(name.trim().replace(/\s+/g, '-')).toLowerCase()
}

export const getOrderCategoriesFields = (services: Service[] = [], categoryId) => {
    if (!categoryId || !services?.length) {
        return [];
    }

    const items: {
        name: keyof createOrderState;
        value: createOrderState[keyof createOrderState];
    }[] = [];

    const serviceList = services.filter(item => item?.service_category?.id === categoryId);
    let activeStep = '';

    if (serviceList?.length === 1) {
        const service = serviceList[0];
        const category = service?.service_category;
        const categorySlug = category?.slug || 'category';
        const typeService = service?.service_type

        items.push(
            { name: SERVICE_TYPE_ID, value: typeService?.id || '' },
            { name: SERVICE_TYPE_SLUG, value: typeService?.slug ? `type-${typeService?.slug}` : 'type' }
        )
        activeStep = `category-${categorySlug}/${typeService?.slug ? `type-${typeService?.slug}` : 'type'}/subtype`

        if (service?.service_sub_types?.length === 1) {
            const subtypeService = service?.service_sub_types[0]?.service_sub_type

            items.push(
                { name: SERVICE_SUB_TYPE_IDS, value: subtypeService?.id ? [subtypeService?.id] : [] },
                { name: SERVICE_SUB_TYPE_SLUG, value: subtypeService?.slug ? `subtype-${subtypeService?.slug}` : 'subtype' }
            )
            activeStep = `category-${categorySlug}/${typeService?.slug ? `type-${typeService?.slug}` : 'type'}/${subtypeService?.slug ? `subtype-${subtypeService?.slug}` : 'subtype'}`
        }

        if (!service?.service_sub_types?.length) {
            items.push(
                { name: SERVICE_SUB_TYPE_IDS, value: [] },
                { name: SERVICE_SUB_TYPE_SLUG, value: `subtype` }
            )
            activeStep = `category-${categorySlug}/${typeService?.slug ? `type-${typeService?.slug}` : 'type'}/subtype`
        }

        items.push(
            { name: "activeStep", value: activeStep },
        )
    }

    return items;
}
