import React, { useEffect, useState } from "react";
import { map, replace } from "lodash";
import { endpoints } from "../../store/directories/directories.api";
import Form from "../../components/Form/Form";
import { toArray } from "../../lib/converters";
import { OrderStepsProps } from "./index";
import { Category } from "../../models/directoryModel";

import {
    CUSTOM_SERVICE_SUB_TYPE,
    CUSTOM_SERVICE_TYPE,
    FILTER_PAGES,
    SERVICE_CATEGORY_ID,
    SERVICE_CATEGORY_NAME,
    SERVICE_CATEGORY_SLUG,
    SERVICE_SUB_TYPE_IDS,
    SERVICE_TYPE_ID,
} from "../../store/order/constant";
import SelectItemToInput from "../../components/SelectItemToInput";
import {
    getItemCreateOrder,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import { useActions } from "../../hooks/actions";
import {
    clearCreateOrderState,
    createOrderState,
} from "../../store/order/createOrder.slice";
import { arrayMove, scrollTop } from "../../untils";
import Advertising from "../../components/Advertising";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const SelectServiceCategories: React.FC<OrderStepsProps> = ({
    entities,
    onSubmit,
    error,
    onSuccess,
    isSuccess,
    isIndividualOrder,
    currentLanguage,
}) => {
    const { category } = useParams();
    const location = useLocation();
    const { updateCreateOrderField } = useActions();
    const [foundCategories, setFoundCategories] = useState<Category[]>([]);
    const [nextStep, setNextStep] = useState<string>(`${category}/type`);
    const activeCategory = getItemCreateOrder(SERVICE_CATEGORY_ID);
    const { data: serviceCategories } =
        endpoints.getServiceCategories.useQuery();
    const categories: Category[] = map(serviceCategories) || [];
    // TODO : костыль по просьбе заказчика для переноса элемента с 79 позиции на 5ую
    arrayMove(
        categories || [],
        categories?.findIndex((el) => el?.id === 79),
        4
    );

    useEffect(() => {
        localStorage.setItem('page', 1);
        const paths = location.pathname.split('/');
        const lastStep = paths[paths.length - 1];

        (!category && lastStep !== 'category') && history.pushState(null, "", 'create-order/');
    }, [])

    useEffect(() => {
        category && setNextStep(category);
    }, [category])

    useEffect(() => {
        setFoundCategories(categories);
    }, [serviceCategories])

    useEffect(() => {
        const filteredCategories = categories.filter(
            (serviceCategory: Category) => activeCategory !== serviceCategory?.id
        );

        setFoundCategories(filteredCategories?.length ? filteredCategories : categories);
    }, [activeCategory])

    useEffect(() => {
        if (!serviceCategories?.length) return;

        const slug = category?.replace(/^category-/gi, '') || ''; 
        const selectedCat = serviceCategories?.find(item => item.slug === slug || item.id === activeCategory);

        if (selectedCat?.id) {
            updateOrderField<typeof SERVICE_CATEGORY_ID>(SERVICE_CATEGORY_ID, selectedCat.id);
            updateOrderField<typeof SERVICE_CATEGORY_SLUG>(SERVICE_CATEGORY_SLUG, selectedCat.slug ? `category-${selectedCat.slug}` : 'category');

            history.pushState(null, "", `category-${selectedCat.slug}`);
            setNextStep(`category-${selectedCat.slug}/type`);
        }

        updateOrderField<typeof SERVICE_CATEGORY_NAME>(SERVICE_CATEGORY_NAME, selectedCat?.name || null);
    }, [serviceCategories])

    const title = entities?.order_select_category_title?.value;
    const description =
        entities?.order_select_service_type?.value;

    const updateOrderField = <K extends keyof createOrderState>(name: K, value: createOrderState[K]) => {
        updateItemCreateOrder(
            {
                name,
                value,
            },
            updateCreateOrderField
        );
    }

    const handleClick = (id: number, slug: string, nameCat: string) => {
        if (isIndividualOrder) {
            return;
        }
        [
            SERVICE_TYPE_ID,
            CUSTOM_SERVICE_TYPE,
            CUSTOM_SERVICE_SUB_TYPE,
            SERVICE_SUB_TYPE_IDS,
            FILTER_PAGES,
        ].forEach((key) =>
            updateItemCreateOrder(
                {
                    name: key as keyof createOrderState,
                    value: clearCreateOrderState[key as keyof createOrderState],
                },
                updateCreateOrderField
            )
        );
        updateOrderField<typeof SERVICE_CATEGORY_ID>(SERVICE_CATEGORY_ID, activeCategory === id ? null : id);
        updateOrderField<typeof SERVICE_CATEGORY_NAME>(SERVICE_CATEGORY_NAME, nameCat || null);
        updateOrderField<typeof SERVICE_CATEGORY_SLUG>(SERVICE_CATEGORY_SLUG, `category-${slug}` || null);

        history.pushState(null, "", slug ? `category-${slug}` : `category`);

        setNextStep(`category-${slug}/type`);
    };

    const handleOnChange = (value: string) => {
        setFoundCategories(
            categories?.filter((item) =>
              item.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())
            ) || categories
        );
    }

    const _onSuccess = () => {
        if (isSuccess) {
            if (nextStep) {
                onSuccess(nextStep);
            }
        }
    };

    return (
        <>
            <h1 className="visually-hidden">{title}</h1>
            <div className="inner-container">
                <div className="new-order__wrapper new-order__wrapper--first">
                    <div className="new-order__header">
                        <h2 className="title title--f46">{title}</h2>
                        <p className="subtitle">{description}</p>
                    </div>
                    <div className="new-order__form form">
                        <Form
                            isSuccess={isSuccess}
                            onSuccess={_onSuccess}
                            onSubmit={(e: React.FormEvent<HTMLInputElement>) => {
                                onSubmit(e, nextStep)
                                scrollTop()
                            }}
                            entities={entities}
                        >
                            {(invalidFields, error) => {
                                const validationError =
                                    invalidFields &&
                                    error &&
                                    error?.errors[SERVICE_CATEGORY_ID] &&
                                    toArray(
                                        error?.errors[SERVICE_CATEGORY_ID]
                                    ).join(`, `);
                                return (
                                    <>
                                        <div className="new-order-start">
                                            <SelectItemToInput
                                                entities={entities}
                                                list={categories}
                                                currentItem={activeCategory}
                                                deleteItem={handleClick}
                                                items={isIndividualOrder ? [] : foundCategories}
                                                onSelect={handleClick}
                                                onChange={handleOnChange}
                                                error={error}
                                                validationError={
                                                    validationError
                                                }
                                                hiddenCloseButton={isIndividualOrder}
                                                isBig
                                            />
                                        </div>
                                        <div className="form__row form__controls">
                                            <button
                                                className="btn btn--transparent btn--bg-blue"
                                                type="submit"
                                                disabled={!activeCategory}
                                            >
                                                {
                                                    entities
                                                        ?.login_continue_button
                                                        ?.value
                                                }
                                            </button>
                                        </div>
                                    </>
                                );
                            }}
                        </Form>
                    </div>
                </div>
                <ul className="advertising-list create-order">
                    <Advertising countCards={5} entities={entities} isHorizontal={true} />
                </ul>
            </div>
        </>
    );
};
export default SelectServiceCategories;
