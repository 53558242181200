import React, { useState } from "react";
import Score from "../../Score";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../../Icon";
import classNames from "classnames";
import { getCurrentLanguage } from "../../../store/directories/selectors";
import {
    ProfilePayload,
    ServiceCategory,
    SpecialistDetailPayload,
    SpecialistPayload,
} from "../../../models/userModels";
import { useUserRole } from "../../../hooks/useUserRole";
import { TranslationObject } from "../../../models/translationModal";
import { createOrderState } from "../../../store/order/createOrder.slice";
import {
    IS_INDIVIDUAL,
    IS_PRIVATE,
    SERVICE_CATEGORY_ID,
    SPECIALIST_ADDRESS,
    SPECIALIST_IDS,
} from "../../../store/order/constant";
import ModalSelectCategory from "../ModalSelectCategory";
import { clearOrderDataInLocalStorage, getItemCreateOrder, updateItemCreateOrder } from "../../../store/order/selectors";
import { chooseDeclension, getOrderCategoriesFields, setItemStore } from "../../../untils";
import { LocationData } from "../../../models/orderModel";
import { useActions } from "../../../hooks/actions";

interface Props {
    user?: SpecialistPayload;
    showAddButton: boolean;
    entities?: TranslationObject;
    pathNameRole?: string;
    fromIndividualOrder: boolean;
    details?: SpecialistDetailPayload;
    updateField: <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) => void;
    profile?: ProfilePayload;
    changeSpecialistIds?: (value: number) => void;
    defaultLink?: string;
    isAllowSelectSpec?: boolean;
    link?: string;
}

const RightSidebar: React.FC<Props> = ({
    user,
    showAddButton,
    entities,
    pathNameRole,
    fromIndividualOrder,
    details,
    updateField,
    profile,
    changeSpecialistIds,
    defaultLink,
    isAllowSelectSpec,
    link,
}) => {
    const specialistIds = getItemCreateOrder(SPECIALIST_IDS);
    const isIndividual = localStorage.getItem(IS_INDIVIDUAL) || false;
    const isPrivate = getItemCreateOrder(IS_PRIVATE);
    const [showModal, setShowModal] = useState<boolean>(false);
    const isSpecSelected = specialistIds?.indexOf(Number(user?.id)) !== -1;
    const isAdd = specialistIds?.indexOf(Number(user?.id)) !== -1;
    const currentLanguage = getCurrentLanguage();
    const reviewsCount = (user && user?.reviews_count) || 0;
    const { role } = useUserRole(pathNameRole);
    const allCategories = details?.services?.map((elem) => elem.service_category) || [];

    const categories = allCategories.reduce(
        (arr: ServiceCategory[], current: ServiceCategory) => {
            return arr.find((elem: ServiceCategory) => elem?.id == current?.id)
                ? arr
                : [...arr, current];
        },
        []
    );

    const specialistAddress: LocationData = {
        address: user?.address,
        latitude: user?.latitude,
        longitude: user?.longitude
    }
    const { updateCreateOrderField } = useActions();

    const navigate = useNavigate();
    const isRussian = currentLanguage === "ru";

    const handleClick = async () => {
        if (isIndividual || !isPrivate || !link) {
            if (categories?.length > 0) {
                if (categories?.length > 1) {
                    return setShowModal(true);
                }

                clearOrderDataInLocalStorage(false, []);

                const items: {
                    name: keyof createOrderState;
                    value: createOrderState[keyof createOrderState];
                }[] = [
                        { name: SPECIALIST_IDS, value: [Number(user?.id)] },
                        { name: IS_PRIVATE, value: true },
                        { name: SERVICE_CATEGORY_ID, value: categories[0]?.id },
                        { name: IS_INDIVIDUAL, value: true },
                        { name: SPECIALIST_ADDRESS, value: specialistAddress },
                        { name: 'isAdvertising', value: true},
                        { name: 'page', value: ''}
                    ];

                const orderCatFields = getOrderCategoriesFields(details?.services || [], categories[0]?.id);

                if (orderCatFields?.length) {
                    items.push(...orderCatFields)
                }

                await items.forEach((elem) => {
                    updateField({
                        name: elem.name,
                        value: elem.value,
                    });
                });
    
                if (profile?.id) {
                    updateField({
                        name: "userId",
                        value: profile?.id,
                    });
                }
    
                return navigate(`/${currentLanguage}/customer/create-order`);
            }

            return;
        }

        if (user?.id) {
            updateUser();
        }
    };

    const specialistDetailsReviewsCount =
        entities?.specialist_details_reviews_count?.value;

    const specialistDetailsCount = entities?.specialist_details_deals?.value;

    const reviewsCountStr = chooseDeclension(
        reviewsCount,
        specialistDetailsReviewsCount,
        isRussian,
    );

    const specialistDetailsCountStr = chooseDeclension(
        Number(user?.deals_count),
        specialistDetailsCount,
        isRussian
    );

    const isSpecialist = profile?.user_role === "specialist";

    const updateUser = () => {
        const user_id = Number(user?.id);

        updateItemCreateOrder(
            {
                name: SPECIALIST_IDS,
                value: specialistIds?.indexOf(user_id) !== -1
                    ? specialistIds?.filter((elem: number) => elem !== user_id)
                    : specialistIds.concat(user_id),
            },
            updateCreateOrderField
        );

        if (link) {
            return navigate(link);
        }

    }

    const closeModalSelectCategory = (isOrder?: boolean) => {
        if (!isOrder) {
            updateField({
                name: IS_INDIVIDUAL,
                value: false,
            });
            localStorage.removeItem(IS_INDIVIDUAL)
        }

        setShowModal(false);
    }

    return (
        <aside className="specialist-page__aside page__aside profile-card profile-card--block">
            <ModalSelectCategory
                entities={entities}
                visible={showModal}
                close={closeModalSelectCategory}
                id={Number(user?.id)}
                categories={categories}
                updateField={updateField}
                profileId={profile?.id}
                specialistAddress={specialistAddress}
                isCatSelect={true}
                services={details?.services || []}
            />
            <Score
                score={user?.reviews_avg_score || 0}
                className="rating rating--block rating--lg"
                width={24}
                height={24}
            />

            <div className="indicators">
                {Number(user?.deals_count) > 0 && (
                    <span className="indicators__deal">
                        {`${user?.deals_count} ${specialistDetailsCountStr}`}
                    </span>
                )}
                {reviewsCount > 0 ? (
                    <Link
                        className="indicators__link-review link link--underline"
                        to={`/${currentLanguage}/${role}/reviews/${user?.id}`}
                    >
                        {`${reviewsCount} ${reviewsCountStr}`}
                    </Link>
                ) : (
                    <span className="indicators__link-review ">{entities?.without_reviews?.value || ""}</span>
                )}
            </div>
            {user && user?.specialist_details_documents_verified && (
                <p className="profile-card__note">
                    <Icon type="ok_ellipse" width={16} height={16} />
                    {entities?.specialist_details_documents_verified?.value}
                </p>
            )}
            {user?.documents_is_verified && (
                <p className="profile-card__note">
                    <Icon type="ok_ellipse" width={16} height={16} />
                    {entities?.specialist_details_documents_verified?.value ||
                        ""}
                </p>
            )}
            <p className="profile-card__note">
                <Icon type="like" width={16} height={16} />
                <span>
                    {user?.completed_orders_percentage || 0}
                    {"% "}
                    {entities?.order_completed_count?.value?.split("/").pop() ||
                        ""}
                </span>
            </p>
            {showAddButton && !isSpecialist && (
                <div className="specialist-page__btn">
                    {

                        (!isIndividual && link) && isSpecSelected ? 
                            <button
                                className={classNames("btn btn--rounded btn--wide btn--transparent")}
                                type="button"
                                data-open-modal="specialist"
                                onClick={updateUser}
                            >
                                {entities?.common_cancel_button?.value}
                            </button> 
                        : <button
                            className={classNames("btn btn--rounded btn--wide")}
                            type="button"
                            data-open-modal="specialist"
                            onClick={handleClick}
                        >
                            {entities?.order_select_specialist?.value}
                        </button>
                    }
                </div>
            )}
        </aside>
    );
};

export default RightSidebar;
