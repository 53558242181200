import React, { useEffect, useState } from "react";
import SubMenu from "../../components/SubMenu";
import { ComponentProps } from "../../models/models";
import { Notification } from "./Notification";
import {
    endpoints,
    useViewAllNotificationMutation,
} from "../../store/notifications/notifications.api";
import Pagination from "../../components/Pagination";
import { getPages } from "../../untils";
import cn from "classnames";
import { useDispatch } from "react-redux";
import { userApi, util as userUtil } from "../../store/user/user.api";
import { ProfilePayload } from "../../models/userModels";
import { useLocation, useNavigate } from "react-router-dom";

interface Props extends ComponentProps {
    profile?: ProfilePayload
}

export const UserNotifications: React.FC<Props> = ({
    entities,
    isSpecialistOnUrl,
    profile,
    currentLanguage
}) => {
    const [getNotificationsList, { data: notifications }] =
        endpoints.getNotificationsList.useLazyQuery();

    const [viewAllNotification] = useViewAllNotificationMutation();

    const [currentPage, setCurrentPage] = useState<number>(1);

    const [pages, setPages] = useState<number[]>([]);

    const changeCurrentPage = (value: number) => {
        setCurrentPage(value);
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    
    useEffect(() => {
        const role = profile?.user_role
        const correctPath = `/${currentLanguage}/${role}/lk/notifications`

        if (role && !pathname.includes(role)) {
            navigate(correctPath)
        }
    }, [])

    useEffect(() => {
        getNotificationsList({
            params: {
                per_page: 5,
                page: currentPage,
            },
        });
    }, [currentPage]);

    const handleClickViewsAllNotifications = () => {
        viewAllNotification()
            .unwrap()
            .then(() =>
                dispatch(
                    userApi.util.invalidateTags([
                        { type: "Profile", id: "PROFILE" },
                    ])
                )
            );
    };

    useEffect(() => {
        if (notifications) {
            const count = Math.ceil(notifications?.total / 5);
            if (count > 1) {
                const pages = getPages(count);
                setPages(pages);
            }
        }
    }, [notifications]);

    useEffect(() => {
        dispatch(userUtil.invalidateTags([{ type: "Profile", id: "PROFILE" }]));
    }, [dispatch]);

    return (
        <>
            <main>
                <SubMenu entities={entities} profile={profile} />
                <section
                    className={cn("notifications", {
                        "notifications-specialist": isSpecialistOnUrl,
                    })}
                >
                    <div className="inner-container">
                        <div className="title-container">
                            <h1 className="title title--f46">
                                {entities?.notifications_title?.value}
                            </h1>
                            <button
                                className="btn btn--transparent"
                                onClick={handleClickViewsAllNotifications}
                            >
                                {entities?.notifications_view_all?.value}
                            </button>
                        </div>
                        <ul className="notifications__list">
                            {notifications?.items?.map((notification) => (
                                <Notification
                                    key={notification?.id}
                                    entities={entities}
                                    notification={notification}
                                    isSpecialist={isSpecialistOnUrl}
                                />
                            ))}
                        </ul>
                        <Pagination
                            pages={pages}
                            entities={entities}
                            changeCurrentPage={changeCurrentPage}
                            currentPage={currentPage}
                        />
                    </div>
                </section>
            </main>
        </>
    );
};
