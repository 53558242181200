import React, { useEffect, useMemo, useState } from "react";
import { endpoints, util as userUtil } from "../../store/user/user.api";
import { endpoints as endpointsReview } from "../../store/review/review.api";
import { useNavigate, useParams } from "react-router-dom";
import { ComponentProps } from "../../models/models";
import { ReviewPayload } from "../../models/reviewModel";
import { useUserRole } from "../../hooks/useUserRole";
import { Language } from "../../models/orderModel";
import { Image, ProfilePayload } from "../../models/userModels";
import Pagination from "../../components/Pagination";
import Score from "../../components/Score";
import Icon from "../../components/Icon";
import ReviewCard from "../../components/ReviewCard";
import Advertising from "../../components/Advertising";
import Avatar from "../../components/Avatar";
import { chooseDeclension, getPages } from "../../untils";
import Loader from "../../components/Loader";
import {
    checkIsMobile,
    getCurrentLanguage,
    isSpecialistUrl,
    getDirectories
} from "../../store/directories/selectors";
import { useDispatch } from "react-redux";

interface IUserInfo {
    avatar?: Image;
    name: string;
    rating?: number | null;
    deals_count?: number;
    reviews_count?: number;
    documents_is_verified?: boolean;
    languages?: Language[];
    completed_orders_percentage?: number | string;
}

interface Props extends ComponentProps {
    profile?: ProfilePayload;
    isMyReviews?: boolean;
}

const Reviews: React.FC<Props> = ({
    entities,
    profile,
    isMyReviews = false,
    isSpecialistOnUrl,
}) => {
    const dispatch = useDispatch();
    const { id: paramsId } = useParams();
    const { id: profileId } = profile || {};
    const [reviews, setReviews] = useState<ReviewPayload | null>(null);
    const [pages, setPages] = useState<number[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const { role } = useUserRole();
    const isSpecialist = profile?.user_role === "specialist";
    const currentLanguage = getCurrentLanguage();
    const isRussian = currentLanguage === "ru";
    const isMobile = checkIsMobile();
    const navigate = useNavigate();
    const { data: directories } = getDirectories();
    const reviewFee = directories?.settings?.review_fee

    let avatarSize = 138;

    if (isMobile) {
        avatarSize = 64;
    }

    useEffect(() => {
        const role = profile?.user_role

        if (isMyReviews && role === 'customer') {
            navigate(`/${currentLanguage}/${role}/notfound`)
        }
    }, [])

    const specialistDetailsReviewsCount =
        entities?.specialist_details_reviews_count?.value;

    const specialistDetailsCount = entities?.specialist_details_deals?.value;

    const [
        getReviewsSpecialist,
        {
            data: getReviewsSpecialistData,
            isSuccess: isSuccessReviewsSpecialist,
            isLoading: isLoadingReviewsBySpecialistId,
        },
    ] = endpointsReview.getReviewsBySpecialistId.useLazyQuery();

    const [
        getReviewsCustomer,
        {
            data: getReviewsCustomerData,
            isSuccess: isSuccessReviewsCustomer,
            isLoading: isLoadingReviewsByCustomerId,
        },
    ] = endpointsReview.getReviewsByCustomerId.useLazyQuery();

    const [getSpecialist, { data: specialist, error: errorSpecialist }] =
        endpoints.getSpecialist.useLazyQuery();

    const [getCustomer, { data: customer, error: errorCustomer }] =
        endpoints.getCustomer.useLazyQuery();

    const [
        getUserReviews,
        {
            data: userReviews,
            isSuccess: isSuccessUserReviews,
            isLoading: isLoadingReviews,
        },
    ] = endpointsReview.getReviews.useLazyQuery();

    const isLoading =
        isLoadingReviews ||
        isLoadingReviewsByCustomerId ||
        isLoadingReviewsBySpecialistId;

    useEffect(() => {
        loadReviews();
    }, [currentPage, paramsId, profileId, role, isMyReviews]);

    const loadReviews = () => {
        const isSpecialist = role === "specialist" || false;
        const per_page = 15;

        if (currentPage && paramsId) {
            if (isMyReviews) {
                return getUserReviews({
                    params: {
                        page: currentPage,
                        per_page,
                    },
                });
            }

            if (isSpecialist && paramsId) {
                // получить отзывы заказчика со стороны специалиста
                getCustomer({ id: +paramsId });
                return getReviewsCustomer({
                    id: +paramsId,
                    params: {
                        page: currentPage,
                        per_page,
                    },
                });
            }

            getSpecialist({ id: +paramsId });
            return getReviewsSpecialist({
                id: +paramsId,
                params: {
                    page: currentPage,
                    per_page,
                },
            });
        }
    };

    useEffect(() => {
        if (isSuccessUserReviews && userReviews) {
            updateList(userReviews);
        } else if (isSuccessReviewsSpecialist && getReviewsSpecialistData) {
            updateList(getReviewsSpecialistData);
        } else if (isSuccessReviewsCustomer && getReviewsCustomerData) {
            updateList(getReviewsCustomerData);
        }
    }, [
        isSuccessUserReviews,
        userReviews,
        isSuccessReviewsSpecialist,
        getReviewsSpecialistData,
        isSuccessReviewsCustomer,
        getReviewsCustomerData,
    ]);

    const userInfo: IUserInfo | Record<string, undefined> = useMemo(() => {
        if (!paramsId || !role) return {};

        if (isMyReviews) {
            return {
                avatar: profile?.avatar,
                name: profile?.name,
                deals_count: profile?.deals_count,
                reviews_count: profile?.reviews_count,
                languages: profile?.languages,
                rating: profile?.reviews_avg_score,
                documents_is_verified: profile?.documents_is_verified,
                completed_orders_percentage:
                    profile?.completed_orders_percentage,
            };
        }
        if (specialist?.id) {
            return {
                avatar: specialist?.avatar,
                name: specialist?.name,
                deals_count: specialist?.deals_count,
                reviews_count: specialist?.reviews_count,
                languages: specialist?.languages,
                documents_is_verified: specialist?.documents_is_verified,
                rating: specialist?.reviews_avg_score,
                completed_orders_percentage:
                    specialist?.completed_orders_percentage,
            };
        } else if (customer?.id) {
            return {
                avatar: customer?.avatar,
                name: customer?.name,
                deals_count: customer?.deals_count,
                reviews_count: customer?.reviews_count,
                languages: customer?.languages,
            };
        }
    }, [specialist, customer, profile, role, profileId, paramsId, isMyReviews]);

    const reviewsCountStr = chooseDeclension(
        Number(userInfo?.reviews_count),
        specialistDetailsReviewsCount,
        isRussian
    );

    const specialistDetailsCountStr = chooseDeclension(
        Number(userInfo?.deals_count),
        specialistDetailsCount,
        isRussian
    );

    const hasReview =
        (userInfo?.reviews_count && userInfo?.reviews_count > 0) || false;

    const textReview = hasReview
        ? reviewsCountStr
        : entities?.without_reviews?.value;

    const updateList = (obj: ReviewPayload) => {
        setReviews(obj);
        const count = Math.ceil(obj.total / 15);
        if (count >= 1) {
            const pages = getPages(count);
            setPages(pages || []);
        }
    };

    const changeCurrentPage = (value: number) => {
        setCurrentPage(value);
    };

    useEffect(() => {
        if (isMyReviews) {
            dispatch(
                userUtil.invalidateTags([{ type: "Profile", id: "PROFILE" }])
            );
        }
    }, [dispatch, isMyReviews]);

    if (isLoading) {
        return <Loader />;
    }

    if (errorSpecialist || errorCustomer) {
        return navigate(
            `/${currentLanguage}/${
                isSpecialistUrl() ? "specialist" : "customer"
            }/notfound`
        );
    }

    return (
        <main>
            <div className="page spec-page">
                <div className="spec-page__container container">
                    <div className="spec-page__wrapper">
                        <div className="spec-page__header">
                            <h1 className="spec-page__title title title--f46">
                                {entities?.specialist_details_reviews?.value}
                            </h1>
                            <div className="user-card">
                                <div className="avatar user-card__avatar">
                                    <Avatar
                                        avatar={userInfo?.avatar}
                                        width={avatarSize}
                                        height={avatarSize}
                                        isSquare
                                    />
                                </div>
                                <div className="user-card__info">
                                    <h2 className="user-card__name title title--f24">
                                        {userInfo?.name}
                                    </h2>
                                    <div className="indicators user-card__indicators">
                                        {!isSpecialistOnUrl && (
                                            <Score
                                                score={userInfo?.rating || 0}
                                                withBackground
                                            />
                                        )}
                                        <span className="indicators__deal">
                                            {userInfo?.deals_count}{" "}
                                            {specialistDetailsCountStr}
                                        </span>
                                        {hasReview ? (
                                            <span className="indicators__deal">
                                                {userInfo?.reviews_count +
                                                    " " +
                                                    textReview}
                                            </span>
                                        ) : (
                                            <span className="indicators__link-review ">
                                                {textReview}
                                            </span>
                                        )}
                                    </div>
                                    {!isSpecialistOnUrl &&
                                        userInfo?.documents_is_verified && (
                                            <p className="user-note user-card__note">
                                                <Icon
                                                    type="ok_ellipse"
                                                    width={16}
                                                    height={16}
                                                />{" "}
                                                {
                                                    entities
                                                        ?.specialist_details_documents_verified
                                                        ?.value
                                                }
                                            </p>
                                        )}
                                    {!isSpecialistOnUrl && (
                                        <p className="user-note user-card__note">
                                            <Icon
                                                type="like"
                                                width={16}
                                                height={16}
                                                className="user-note__icon"
                                            />
                                            {userInfo?.completed_orders_percentage ||
                                                0}
                                            %{" "}
                                            {
                                                entities?.order_completed_count
                                                    ?.value
                                            }
                                        </p>
                                    )}
                                </div>
                                <div className="languages languages--full languages--big user-card__lang">
                                    <p className="languages__title">
                                        {
                                            entities
                                                ?.common_communication_languages
                                                ?.value
                                        }
                                        :
                                    </p>
                                    <div className="languages__wrapper">
                                        <ul className="languages__list">
                                            {userInfo?.languages?.map(
                                                (
                                                    language: Language,
                                                    key: number
                                                ) => (
                                                    <li
                                                        className="languages__item popover"
                                                        key={key}
                                                    >
                                                        <span className="popover__tip">
                                                            {language?.name}
                                                        </span>
                                                        <img
                                                            src={
                                                                language?.flag
                                                                    ?.original_url
                                                            }
                                                            width="22"
                                                            height="16"
                                                            alt={language?.name || 'language'}
                                                        />
                                                    </li>
                                                )
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(reviews?.items?.length && (
                            <ul className="reviews list-reset">
                                {reviews?.items?.map((review) => (
                                    <ReviewCard
                                        reviewFee={reviewFee}
                                        isSpecialist={isSpecialist}
                                        review={review}
                                        entities={entities}
                                        key={review.id}
                                        viewForSpecialist={
                                            profile?.user_role === "specialist"
                                        }
                                    />
                                ))}
                                {pages?.length > 1 && (
                                    <Pagination
                                        pages={pages}
                                        entities={entities}
                                        currentPage={currentPage}
                                        changeCurrentPage={changeCurrentPage}
                                    />
                                )}
                            </ul>
                        )) || (
                            <div className="not-reviews__block">
                                <p>{entities?.without_reviews?.value}</p>
                            </div>
                        )}
                    </div>
                    <div className="useful-info__list reviews-page">
                    {/* <ul className="advertising-list reviews-page"> */}
                        <Advertising
                            typeAdvertising={
                                isSpecialist ? "thirdParties" : "specialists"
                            }
                            entities={entities}
                            isHorizontal={true}
                        />
                    </div>
                </div>
            </div>
        </main>
    );
};
export default Reviews;
