import React, { useEffect } from "react";
import { ComponentProps } from "../../models/models";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { ProfilePayload } from "../../models/userModels";
import Profile from "../Profile";
import { EditCompany } from "./elements/EditCompany";
import { getSpecialistRole } from "./utils";
import { ProfileSpecialistContent } from "./elements/ProfileSpecialistContent/ProfileSpecialistContent";

interface ProfileSpecialistProps extends ComponentProps {
    profile?: ProfilePayload;
}

export const ProfileSpecialist: React.FC<ProfileSpecialistProps> = ({
    entities,
    currentLanguage,
    isSpecialistOnUrl,
    isCustomerOnUrl,
    profile,
}) => {
    const userSpecialistRole = getSpecialistRole(profile, entities);
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (profile?.user_role === 'specialist' && pathname.includes('customer')) {
            navigate(pathname.replace('customer', 'specialist'))
        }

        if (profile?.user_role !== 'specialist' && pathname.includes('specialist')) {
            navigate(pathname.replace('specialist', 'customer'))
        }
    }, [])

    return (
        <>
            <Routes>
                <Route
                    path={"/"}
                    element={
                        <ProfileSpecialistContent
                            entities={entities}
                            currentLanguage={currentLanguage}
                            isCustomerOnUrl={!!isCustomerOnUrl}
                            isSpecialistOnUrl={!!isSpecialistOnUrl}
                            profile={profile}
                            userSpecialistRole={userSpecialistRole}
                        />
                    }
                />
                <Route
                    path={"account"}
                    element={
                        <Profile
                            entities={entities}
                            currentLanguage={currentLanguage}
                            isCustomerOnUrl={!!isCustomerOnUrl}
                            isSpecialistOnUrl={!!isSpecialistOnUrl}
                            profile={profile}
                        />
                    }
                />
                <Route
                    path={"company-edit"}
                    element={
                        <EditCompany
                            entities={entities}
                            profile={profile}
                            userSpecialistRole={userSpecialistRole}
                        />
                    }
                />
            </Routes>
        </>
    );
};
